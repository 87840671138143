import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { urls } from '../../config';
import Loader from '../common/Loader';
import TermsScrollDisplay from '../../legal/TermsScrollDisplay';
import QuickAck from '../../legal/QuickAck';


const RegistrationForm = ({ hasLabel, setMode, setRegistrationResponse, emailAddr, autoLogin }) => {
  // State
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState(emailAddr ? emailAddr : '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showSiteTerms, setShowSiteTerms] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    QuickAck({source: "Registration", writeCookie: true, username: email});
    
    let responseCode = 200; 
    
    setIsProcessing(true);

    fetch(urls.account + 'Users/register', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({"firstname": firstname, "lastname": lastname, "username": email, "password": password, "autoLogin": autoLogin})
    })
    .then(response=>{
        responseCode = response.status;
        return response.json();
    })            
    .then(data=>{

        setIsProcessing(false);

        if (responseCode !== 200){

            if (data){
                if (data.ValidationResult && data.ValidationResult.HasErrors){
                    let i = 0;
                    for (i = 0; i < data.ValidationResult.ValidationErrors.length; i++){

                        var errorMsg = data.ValidationResult.ValidationErrors[i].Error;

                        if (errorMsg.includes("Duplicate")){
                          errorMsg = "This email account already exists. Select the option to Login. You may reset your password if needed.";
                        }


                        toast.error(errorMsg);
                    }
                }
            }
            else{
                toast.error("Unable to register, try again!");
            }
            return;
        }
        
        if (data){

            if (data.Id <= 0){
                toast.error("Unable to register, try again!");
                return;
            }
            
            setRegistrationResponse(data);

            if (data.EmailConfirmed && data.EmailConfirmed === true){
              setMode('done');
            }
            else{
              setMode('confirm');
            }
        }
    },
    (error)=>{
      setIsProcessing(false);
      toast.error("FAILED!");
    });
  };

  const checkMatchingPasswords = () => {

    if (password !== confirmPassword)    {
      toast.error("Passwords do NOT match!");
    }
  }

  useEffect(() => {
    setIsDisabled(!firstname || !lastname || !email || !password || !confirmPassword || !isAccepted || password !== confirmPassword);
  }, [firstname, lastname, email, password, confirmPassword, isAccepted]);

  return (
    <div>        
      <Modal isOpen={showPrivacy} centered toggle={() => setShowPrivacy(!showPrivacy)}>
      <ModalHeader toggle={() => setShowPrivacy(!showPrivacy)}>Privacy Policy</ModalHeader>
        <ModalBody className="p-0">
            <TermsScrollDisplay terms="privacy-policy" source="Registration" />
        </ModalBody>
      </Modal>
      <Modal isOpen={showSiteTerms} centered toggle={() => setShowSiteTerms(!showSiteTerms)}>
      <ModalHeader toggle={() => setShowSiteTerms(!showSiteTerms)}>Website Terms of Use</ModalHeader>
        <ModalBody className="p-0">
            <TermsScrollDisplay terms="site-terms" source="Registration" />
        </ModalBody>
      </Modal>
      <Form onSubmit={handleSubmit}>
        
        <div className="form-row">
          <FormGroup className="col-6">
            {hasLabel && <Label>First Name</Label>}
            <Input autoFocus={true} placeholder={!hasLabel ? 'First Name' : ''} value={firstname} onChange={({ target }) => setFirstName(target.value)} />
          </FormGroup>
          <FormGroup className="col-6">
            {hasLabel && <Label>Last Name</Label>}
            <Input placeholder={!hasLabel ? 'Last Name' : ''} value={lastname} onChange={({ target }) => setLastName(target.value)} />
          </FormGroup>
        </div>
        <FormGroup>
          {hasLabel && <Label>Email address</Label>}
          <Input
            placeholder={!hasLabel ? 'Email address' : ''}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            type="email"
          />
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-6">
            {hasLabel && <Label>Password</Label>}
            <Input
              placeholder={!hasLabel ? 'Password' : ''}
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              type="password"
            />
          </FormGroup>
          <FormGroup className="col-6">
            {hasLabel && <Label>Confirm Password</Label>}
            <Input
              placeholder={!hasLabel ? 'Confirm Password' : ''}
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
              onBlur={checkMatchingPasswords}
              type="password"
            />
          </FormGroup>
        </div>

        <CustomInput
          id="customCheckTerms"
          label={
            <Fragment>
              I accept the <Link to="#!" onClick={() => {setShowSiteTerms(true)}}>terms</Link> and <Link to="#!" onClick={() => {setShowPrivacy(true)}}>privacy policy</Link>
            </Fragment>
          }
          checked={isAccepted}
          onChange={({ target }) => setIsAccepted(target.checked)}
          type="checkbox"
        />
        <FormGroup>
          {isProcessing && <Loader size="lg" padding="2" type="border" />}
          {!isProcessing && 
            <Button color="primary" block className="mt-3" disabled={isDisabled}>
              Register
            </Button>
          }
        </FormGroup>
      </Form>
    </div>
  );
};

RegistrationForm.propTypes = {
  
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

RegistrationForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default RegistrationForm;
