import React, { useState } from 'react';
import { Card, CardBody, Modal, ModalBody, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import Registration from '../auth/basic/Registration';
import ProfileMyEvents from './ProfileMyEvents';
import LoginDropdown from './LoginDropdown';
import { useAuth } from '../../AuthContext';


const TopNavRightSideNavItem = () => {
  
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  
  const { username, token, isAdmin, isLoggedIn } = useAuth();

  if (isLoggedIn){
    return (
        <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
          <ProfileMyEvents token={token} />
          <ProfileDropdown username={username} isAdmin={isAdmin} />
        </Nav>
    );
  }
  else{
    return (
      <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
        <NavItem>
          <NavLink className="pr-2" tag={Link} to="#!" onClick={() => setShowRegistrationModal(!showRegistrationModal)}>
            Register
          </NavLink>
          <Modal isOpen={showRegistrationModal} centered toggle={() => setShowRegistrationModal(!showRegistrationModal)}>
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <Registration />
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </NavItem>
        <LoginDropdown />
    </Nav>
    );
  }
};

export default TopNavRightSideNavItem;
