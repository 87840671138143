import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { omit } from 'lodash';

const Loader = props => (
  <Row className={(!!props.alignCenter ? 'flex-center ' : '') + `py-${props.padding}`}>
    <Col xs="auto">
      <Spinner {...omit(props, ['alignCenter'])} />
    </Col>
  </Row>
);

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary',
  padding: '5',
  alignCenter: true
};

export default Loader;
