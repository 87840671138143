import React, { Fragment, useState } from 'react';
import { Button } from 'reactstrap';
import CookieAlert from '../components/bootstrap-components/CookieAlert';
import cookieImage from '../assets/img/icons/cookie.png';
import QuickAck from './QuickAck';

const CookieDisplay = () => {
  const [visible, setVisible] = useState(false);

  const agreeClicked = () => {
    
    setVisible(false);
    QuickAck({source: "Cookie", writeCookie: true});
  }

  return (

    <Fragment>
      <CookieAlert visible={visible} setVisible={setVisible} autoShow={true} autoShowDelay={5000} showOnce={false} cookieExpireTime={60000}>
        <img src={cookieImage} alt="" width={20} className="mr-2" />
        <p className="mb-2 mb-lg-0">
          Our site uses cookies. By continuing to use our site, you agree to our{' '}
          <a href="/terms?source=Cookie" target="_blank" className="text-underline">
            {' '}
            Website Terms of Use
          </a>
          {' '}and{' '}
          <a href="/privacy?source=Cookie" target="_blank" className="text-underline">
            {' '}
            Privacy Policy
          </a>
          .
        </p>
        <Button color="primary" size="sm" className="ml-2" onClick={agreeClicked}>
          Ok, I Understand
        </Button>
        
      </CookieAlert>
    </Fragment>
  );
};

export default CookieDisplay;
