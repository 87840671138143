import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import TermsText from './TermsText';
import PropTypes from 'prop-types';

const TermsScrollDisplay = ({terms, source, termskey}) => {

        return (
            <Scrollbars
                style={{height: 500}}>
                <div className="pad-20">
                    <TermsText terms={terms} source={source} termskey={termskey} />
                </div>
            </Scrollbars>
        );
}

TermsScrollDisplay.propTypes = {
    terms: PropTypes.oneOf(['site-terms', 'privacy-policy', 'event-terms']),
    source: PropTypes.string,
    termskey: PropTypes.string
};

TermsScrollDisplay.defaultProps = {
    terms: 'site-terms'
};

export default TermsScrollDisplay;