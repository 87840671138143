import React, {useState, useEffect} from 'react';
import { urls } from '../config';
import Loader from '../components/common/Loader';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

const TermsText = ({terms, source, termskey}) => {

    const [termsText, setTermsText] = useState("");

    useEffect(() => {

        let responseCode = 200; 

        var eventTermsKey = termskey && termskey.length > 0 ? "key=" + termskey : "";
        if (source && eventTermsKey.length > 0){
            eventTermsKey = "&" + eventTermsKey;
        }
        else if (eventTermsKey.length > 0){
            eventTermsKey = "?" + eventTermsKey;
        }
    
        fetch(urls.legal + `Agreement/${terms}${source ? '?Source=' + source : ''}${eventTermsKey.length > 0  ? eventTermsKey : ""}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response=>{
            responseCode = response.status;
            return response.json();
        })            
        .then(data=>{
    
            if (responseCode === 200 && data){

                setTermsText(data.Body);
            }
        },
        (error)=>{
          setTermsText("Unable to Load " + terms);
        });

      }, [setTermsText, source, terms, termskey]);

    return (
        <div>
            {termsText.length === 0 && <Loader size="lg" padding="2" type="border" /> }
            {termsText.length > 0 && 
               ReactHtmlParser(termsText)
            }
        </div>
    );
}

TermsText.propTypes = {
    terms: PropTypes.oneOf(['site-terms', 'privacy-policy', 'event-terms']),
    source: PropTypes.string,
    termskey: PropTypes.string
};

TermsText.defaultProps = {
    terms: 'site-terms'
};

export default TermsText;