import React, { useState, useEffect } from 'react';
import { CardBody, Card, Row, Col, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ProfileDropDownHelper } from './ProfileDropDownHelper';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { isIterableArray } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

var helper = new ProfileDropDownHelper();

const MyEventItem = ({ EventName, EntryDescription, UrlKey, TicketType }) => {
  
    return (
        <div>
            <Row noGutters className="pt-2 px-3">
                <Col xs={12} className="mt-1 mt-md-0 text-600">
                    <Link target="_blank" to={"/e/challenger/" + UrlKey}>{EventName}<br/>{TicketType}</Link>
                </Col>
            </Row>
            <Row noGutters className="border-bottom pb-2 pl-5 pr-3">
                <Col xs={12}>
                    {isIterableArray(EntryDescription) && EntryDescription.map((item, index) => <span key={index}>{item}<br/></span>)}
                </Col>
            </Row>
        </div>
    );
  };

const ProfileMyEvents = ({token}) => {

    // State
    const [isOpen, setIsOpen] = useState(false);

    // Handler
    const handleToggle = e => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const [myEvents, setMyEvents] = useState(null);

    useEffect(() => {

        helper.getMyEvents(token, setMyEvents);
           
      }, [token, setMyEvents]);

    if (myEvents && isIterableArray(myEvents.MyEvents)){
        return(
            <Dropdown
                nav
                inNavbar
                isOpen={isOpen}
                toggle={handleToggle}
                className="pr-2"
                onMouseOver={() => {
                let windowWidth = window.innerWidth;
                windowWidth > 992 && setIsOpen(true);
                }}
                onMouseLeave={() => {
                let windowWidth = window.innerWidth;
                windowWidth > 992 && setIsOpen(false);
                }}
            >
                <DropdownToggle nav>
                    My Events
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-card">
                <Card className="card-notification shadow-none">
                    <FalconCardHeader title="My Events"/>
                    <CardBody className="pt-0 pb-2 px-3">
                        <Scrollbars
                            style={{height: 500}}>
                            {isIterableArray(myEvents.MyEvents) ? myEvents.MyEvents.map((event, index) => <MyEventItem {...event} key={index} />) : <p>No events to display!</p>}
                            <div className="pb-3"></div>
                        </Scrollbars>
                    </CardBody>
                </Card>
                </DropdownMenu>
            </Dropdown> 
        );
    }
    else{
        return (<div></div>);
    }

}

export default ProfileMyEvents