import React, { Fragment, useContext } from 'react';
import { Navbar } from 'reactstrap';
import classNames from 'classnames';
import AppContext from '../../context/Context';
import Logo from './Logo';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import { Link } from 'react-router-dom';

const MenuContent = () => {

  return(
      <Fragment>
        <Link to='/'>
          <Logo at="navbar-top"  id="topLogo" />
        </Link>
        <TopNavRightSideNavItem />
      </Fragment>
  );
}

const NavbarTop = ({showBurger}) => {
  const {
    showBurgerMenu,
    setShowBurgerMenu,
    isTopNav,
    isVertical,
    isCombo,
    navbarCollapsed,
    setNavbarCollapsed
  } = useContext(AppContext);
  const handleBurgerMenu = () => {
    isTopNav && !isCombo && setNavbarCollapsed(!navbarCollapsed);
    (isCombo || isVertical) && setShowBurgerMenu(!showBurgerMenu);
  };
  return (
    <Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand={isTopNav && topNavbarBreakpoint} >
      {showBurger &&
        <div className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
            'd-lg-none': isTopNav && !isCombo,
            [`d-${navbarBreakPoint}-none`]: isVertical || isCombo
          })}
        >

          <button className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center" onClick={handleBurgerMenu} id="burgerMenu" >
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        
        </div>
      }
      {!showBurger &&
      <div className="container">
        <MenuContent />
      </div>}
      {showBurger && <MenuContent/>}
    </Navbar>
    
  );
};

export default NavbarTop;
