import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RegistrationComplete = ({ setMode, registrationResponse, setRegistrationSuccess }) => {

  const close = (e) => {

    if (setRegistrationSuccess){
      e.preventDefault();
      setRegistrationSuccess(true);
    }
  }
  
  return (
    <Fragment>
      <div className="centerInput mb-4">
        <FontAwesomeIcon icon="check-circle" className="text-success fs-7" />
      </div>
      <p className="centerInput">
        Your email is confirmed with Crowdcatcher.
      </p>
      <div className="centerInput">
        <Button className="mt-3" tag={Link} color="primary" size="sm" onClick={close} to={'/auth/login'}>
          <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
          Proceed to login
        </Button>
      </div>
    </Fragment>
  );
}

RegistrationComplete.propTypes = {
  setMode : PropTypes.func,
  registrationResponse: PropTypes.object, 
  setRegistrationSuccess: PropTypes.func
};



export default RegistrationComplete;
