import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { each, isNil } from 'lodash';
import { settings } from './config';

export const EventPreviewContext = React.createContext();

export const useEventPreview = () => useContext(EventPreviewContext);

const getIsAvailable = (params, roles, sid) => {

    // cannot make an assessment without event params
    if (isNil(params)){
        return true;
    }

    if (params.IsPublished){
        return true;
    }

    if (roles.includes("GlobalAdmin")){
        return true;
    }

    if (isNil(sid) || isNil(params.AccountKey)){
        return false;
    }

    if (roles.includes("Admin") && sid === params.AccountKey){
        return true;
    }

    return false;
}

export const EventPreviewProvider = ({ children }) => {

    const { roles, sid } = useAuth();

    const [eventParams, setEventParams] = useState(null);
    
    useEffect(()=>{
        if (settings.isDebug){
            let roleList = "";
            each(roles, function(role){
                roleList += role + ",";
            });

            console.log(`EVENT PREVIEW ASSESSMENT\nsid ${sid}\nroles ${roleList}\nIsPublished ${eventParams && eventParams.IsPublished}\nAccountKey ${eventParams && eventParams.AccountKey}`);
        }

        if (!getIsAvailable(eventParams, roles, sid)){
            window.location = '/errors/404';
        }
    },[eventParams, roles, sid]);

    return (
        <EventPreviewContext.Provider
            value={{
                setEventParams,
                isAvailable: getIsAvailable()
            }}
        >
            {children}
        </EventPreviewContext.Provider>
    );
};