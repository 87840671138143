import React,{Component} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { AccountProvider } from './AccountContext';
import { AuthProvider } from './AuthContext';
import { ApiProvider } from './ApiContext';

export default class App extends Component{

    render () {
      return (
        <ApiProvider>
          <AuthProvider>
            <AccountProvider>
              <Router>
                <Layout />
              </Router>
            </AccountProvider>
          </AuthProvider>
        </ApiProvider>
    );
  }

}
