import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import NavbarTop from '../components/navbar/NavbarTop';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import { EventPreviewProvider } from '../EventPreviewContext';

const StandardRoutes = loadable(() => import('./StandardRoutes'));

const StandardLayout = ({ location }) => {
  const { isFluid } = useContext(AppContext);


  useEffect(() => {
    StandardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
        <div className="content">
          <NavbarTop showBurger={false} />
          <EventPreviewProvider>
            <Switch>
              {/*Event Routes*/}
              <Route path="/e" component={StandardRoutes} />
              {/*Checkin Routes*/}
              <Route path="/checkin" component={StandardRoutes} />
              {/*DataExport Routes*/}
              <Route path="/data-export" component={StandardRoutes} />
              {/*Passwordless-Login Routes*/}
              <Route path="/quick-login" component={StandardRoutes} />
            </Switch>
          </EventPreviewProvider>
          <Footer />
        </div>
    </div>
  );
};

StandardLayout.propTypes = { location: PropTypes.object.isRequired };

export default StandardLayout;
