import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import LoginForm from '../auth/LoginForm';
import FalconCardHeader from '../common/FalconCardHeader';

const LoginDropdown = () => {

  // State
  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle nav>
        Login
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none">
          <FalconCardHeader title="Login"/>
          <CardBody className="pt-0 pb-2 px-3">
            <LoginForm />
          </CardBody>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LoginDropdown;
