export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
  userData: null,
  ticketPriceToPay: 0.0,
  tinyApiKey: process.env.REACT_APP_TINY_API_KEY,
  isDebug: (process.env.REACT_APP_IS_DEBUG==="true")
};

export const version = '2.19';
export const urls = {
  resourcesRoot: process.env.REACT_APP_RESOURCES_ROOT,
  account: process.env.REACT_APP_CROWDACCOUNT_URL,
  legal: process.env.REACT_APP_CROWDLEGAL_URL,
  eventcore: process.env.REACT_APP_CROWDEVENTCORE_URL,
  finance: process.env.REACT_APP_CROWDFINANCE_URL,
  content: process.env.REACT_APP_CROWDCONTENT_URL,
  reporting: process.env.REACT_APP_CROWDREPORTING_URL,
  comms: process.env.REACT_APP_CROWDCOMMS_URL
};

export const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const defaultWebPage = process.env.REACT_APP_DEFAULT_WEB_PAGE;

export const stripeTerminal = process.env.REACT_APP_STRIPE_TERMINAL;

export const EntryFormMode = {
  EDIT: "EDIT",
  CREATE: "CREATE"
};

export const AccessMode = {
  USER: "USER",
  ADMIN: "ADMIN"
};

export const TransferRecipientType = {
  SMS: 1,
  EMAIL: 2
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings, urls };
