import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import avatar from '../../assets/img/icons/default_avatar_32.png';
import Avatar from '../common/Avatar';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProfileDropdown = ({username, isAdmin}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={avatar} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          {isAdmin &&
            <div>
              <DropdownItem tag={Link} to="/dashboard">
                <FontAwesomeIcon icon="users-cog" className="mr-1" />
                <span>Manage my events</span>
              </DropdownItem>
              <DropdownItem divider />
            </div>
          }
          <DropdownItem tag={Link} to="/auth/logout">
            <strong>Logout</strong> {username}
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

ProfileDropdown.protoTypes = {
  isAdmin: PropTypes.bool
};

ProfileDropdown.defaultProps = {
  isAdmin: false
};

export default ProfileDropdown;
