import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import RegistrationForm from '../RegistrationForm';
import ConfirmRegistrationForm from '../ConfirmRegistrationForm';
import RegistrationComplete from '../RegistrationComplete';
import AppContext from '../../../context/Context';
import { createCookie } from '../../../helpers/utils';

const Registration = ({setRegistrationSuccess, email, autoLogin}) => {

  const {setUserData} = useContext(AppContext);
  const [mode, setMode] = useState('details');
  const [registrationResponse, setRegistrationResponse] = useState(null);

  const close = (e) => {

    if (setRegistrationSuccess){
      e.preventDefault();
      setRegistrationSuccess(true);
    }
  }

  useEffect(() => {
    
    if (registrationResponse && registrationResponse.BasicAuthenticationOk && registrationResponse.Tokens){
      setUserData(registrationResponse);
      createCookie("cc_userdata", registrationResponse, 86000000);  // just less than one day!
      setRegistrationSuccess(true);
    }

  }, [registrationResponse, setRegistrationSuccess, setUserData]);

  return (
    <Fragment>      
      { mode === 'details' && 
        <Fragment>
          <Row className="text-left">
            <Col>
              <h5 id="modalLabel">Register</h5>
            </Col>
            <Col xs="auto">
              <p className="fs--1 text-600">
                Have an account? <Link onClick={close} to={'/auth/login'}>Login</Link>
              </p>
            </Col>
          </Row>
          <RegistrationForm setMode={setMode} setRegistrationResponse={setRegistrationResponse} emailAddr={email} autoLogin={autoLogin} />
        </Fragment>
      }
      { mode === 'confirm' &&
        <ConfirmRegistrationForm setMode={setMode} registrationResponse={registrationResponse} />
      }
      { mode === 'done' && !registrationResponse.Tokens &&
        <RegistrationComplete setMode={setMode} registrationResponse={registrationResponse} setRegistrationSuccess={setRegistrationSuccess} />
      }
    </Fragment>)
  };

export default Registration;
