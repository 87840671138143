import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import StandardLayout from './StandardLayout';
import ErrorLayout from './ErrorLayout';
import loadable from '@loadable/component';
import CookieDisplay from '../legal/CookieDisplay';
import { defaultWebPage } from '../config';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const DashboardLayout = loadable(() => import('./DashboardLayout'));
const Privacy = loadable(() => import('../components/landing/Privacy'));
const Terms = loadable(() => import('../components/landing/Terms'));

const DefaultHomePage = () => {
  window.location = defaultWebPage;

  return "";
}

const Layout = () => {
  
  useEffect(() => {
    AuthBasicLayout.preload();
    
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={DefaultHomePage} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/auth" component={AuthBasicLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/e" component={StandardLayout} />
        <Route path="/checkin" component={StandardLayout} />
        <Route path="/data-export" component={StandardLayout} />
        <Route path="/quick-login" component={StandardLayout} />
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
      <CookieDisplay />
    </Router>
  );
};

export default Layout;
