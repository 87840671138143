
import { urls } from '../config';
import {createCookie} from '../helpers/utils';
import PropTypes from 'prop-types';

const QuickAck = ({source, writeCookie, otherAgreementKeys, token, username}) => {

    let responseCode = 200;   
    
    let headerdata = {"Content-Type": "application/json"};
    if (token){
        headerdata.Authorization = "Bearer " + token;
    }

    fetch(urls.legal + 'Ack/quick', {
      method: "POST",
      headers: headerdata,
      body: JSON.stringify({"Source": source, "Username": username, "OtherAgreementKeys": otherAgreementKeys})
    })
    .then(response=>{
        responseCode = response.status;
        return response.json();
    })            
    .then(data=>{

        if (data && responseCode === 200 && writeCookie){
            createCookie('cc_cookie', JSON.stringify(data), 2592000000);
        }
    });
}

QuickAck.propTypes = {
    source: PropTypes.string,
    username: PropTypes.string,
    writeCookie: PropTypes.bool
};

QuickAck.defaultProps = {
    writeCookie: false,
    source: '',
    username: null
};

export default QuickAck;