import React, { useContext, useEffect, useState } from 'react';
import { useApi } from './ApiContext';
import { useAuth } from './AuthContext';
import { urls } from './config';

export const AccountContext = React.createContext();

export const useAccount = () => useContext(AccountContext);

export const AccountProvider = ({ children }) => {

    const { basicQuery } = useApi();
    const { token, roles } = useAuth();

    const [accounts, setAccounts] = useState([]);

    useEffect(()=>{

        if (token && roles && (roles.includes("Admin") || roles.includes("GlobalAdmin"))){
            basicQuery(urls.account + "Account/get-account-list", token, setAccounts, "89999");
        }
            
    },[basicQuery, token, roles]);

    return (
        <AccountContext.Provider
            value={{
                accounts
            }}
        >
            {children}
        </AccountContext.Provider>
    );
};
