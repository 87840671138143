import moment from 'moment';
import { settings } from '../config';

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));

export const getItemFromSessionStore = (key, defaultValue, store = sessionStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToSessionStore = (key, payload, store = sessionStorage) => store.setItem(key, JSON.stringify(payload));

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {   // cookie expiry time is NUMBER OF MILLISECONDS e.g. 1 day = 86,400,000
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

//===============================
// Timezone
//===============================
export const convertIncomingDate = (input) => {

  if (!input){
    return null;
  }

  // incoming date format is:
  // YYYY-MM-DDThh:mm:ss
  let result = new Date();

  result.setFullYear(parseInt(input.substr(0, 4)));
  result.setMonth(parseInt(input.substr(5, 2))-1);
  result.setDate(parseInt(input.substr(8, 2)));
  result.setHours(parseInt(input.substr(11, 2)));
  result.setMinutes(parseInt(input.substr(14, 2)));
  result.setSeconds(parseInt(input.substr(17, 2)));

  return result;
}

export const getMonthName = (index) => {

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  return months[index];
}

export const stripTimezoneInfo = (input) => {

  if (!input){
    return null;
  }

  let result = "";

  result = moment(input).format();

  const parts = result.split("T");

  result = parts[0] + "T00:00:00Z";

  return new Date(result);
}

export const convertOutgoingDate = (input) => {

  if (!input){
    return null;
  }

  // outgoing date format is:
  // YYYY-MM-DDThh:mm:ss
  let result = "";

  result += String(input.getFullYear()).padStart(4, '0');
  result += "-";
  result += String(input.getMonth()+1).padStart(2, '0');
  result += "-";
  result += String(input.getDate()).padStart(2, '0');
  result += "T";
  result += String(input.getHours()).padStart(2, '0');
  result += ":";
  result += String(input.getMinutes()).padStart(2, '0');
  result += ":";
  result += String(input.getSeconds()).padStart(2, '0');

  return result;
}


//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
  if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
  if (endDate && !moment.isMoment(endDate))
    throw new Error(`End date must be a moment object, received ${typeof startDate}`);

  return `${startDate.format('ll')} - ${endDate ? endDate.format('ll') : 'Present'} • ${startDate.from(
    endDate || moment(),
    true
  )}`;
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#fdfdfd',
  200: '#fafafa',
  300: '#dadada',
  400: '#bababa',
  500: '#9a9a9a',
  600: '#7a7a7a',
  700: '#6a6a6a',
  800: '#5a5a5a',
  900: '#4a4a4a',
  1000: '#3a3a3a',
  1100: '#2a2a2a',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#fdfdfd',
  1000: '#fafafa',
  900: '#dadada',
  800: '#bababa',
  700: '#9a9a9a',
  600: '#7a7a7a',
  500: '#6a6a6a',
  400: '#5a5a5a',
  300: '#4a4a4a',
  200: '#3a3a3a',
  100: '#2a2a2a',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

//===============================
// Host
//===============================

export const getFullHost = () => {

  let host = window.location.hostname.toLowerCase();
  let port = "";

  if (window.location.port === 80 && window.location.protocol.toLowerCase() !== "http:"){
    port = "80";
  }
  if (window.location.port === 443 && window.location.protocol.toLowerCase() !== "https:"){
    port = "443";
  }
  if (window.location.port !== 443 && window.location.port !== 80){
    port = "" + window.location.port;
  }

  return window.location.protocol + "//" + host + (port.length > 0 ? ":" + port : "") + "/";
}

export const responseCodeOk = (code) => {
  if (code >= 200 && code < 300){
    return true;
  }

  return false;
}

// logs to console ONLY if the isDebug setting is turned on
export const logDebug = (output) => {
  if (settings.isDebug){
    console.log(output);
  }
}


//===============================
// Download File (e.g CSV)
//===============================

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

