import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import envelope from '../../assets/img/illustrations/envelope.png';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { urls } from '../../config';
import Loader from '../common/Loader';

const ConfirmRegistrationForm = ({ setMode, registrationResponse }) => {

  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setDisabled(!(code && code.length === 6));
  }, [code]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setDisabled(true);
    setIsProcessing(true);
    
    let responseCode = 200;    

    fetch(urls.account + 'Users/confirm', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({"username": registrationResponse.Username, "code": code})
    })
    .then(response=>{
        responseCode = response.status;
        return response.json();
    })            
    .then(data=>{

        setDisabled(false);
        setIsProcessing(false);

        if (responseCode !== 200){

            if (data){
                toast.error(data.Error);
                toast.error(data.AttemptsRemaining + " attempts remaining!");
            }
            else{
                toast.error("Unable to confirm, try again!");
            }
            return;
        }
        
        if (data){
            setMode('done');
        }
    },
    (error)=>{
        setDisabled(false);
        setIsProcessing(false);
        toast.error("FAILED!");
    });
  };
  
  return (

    <Form onSubmit={handleSubmit}>
      <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={70} />
      <h4 className="centerInput">Please check your email!</h4>
      <p className="centerInput">
        An email has been sent to <strong>{registrationResponse.Username}</strong><br/><br/><strong>Please enter the 6 digit code you received in the email.</strong>
      </p>
    
    <FormGroup>
      
      <Input
        autoFocus={true}
        placeholder={'Code'}
        value={code}
        onChange={({ target }) => setCode(target.value)}
        type="number"
        pattern="[0-9]*"
        maxLength={6}
        className="centerInput input-spin-none"
      />
    </FormGroup>
    <FormGroup>
      {isProcessing && <Loader size="lg" padding="2" type="border" />}
      {!isProcessing && 
        <Button color="primary" block className="mt-3" disabled={disabled}>
          Confirm
        </Button>
      }
    </FormGroup>
    </Form>
  );
}

ConfirmRegistrationForm.propTypes = {
  setMode : PropTypes.func,
  registrationResponse: PropTypes.object 
};


export default ConfirmRegistrationForm;
