import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logodark from '../../assets/img/illustrations/Crowdcatcher.png';
import logolight from '../../assets/img/illustrations/Crowdcatcher.png';

const Logo = ({ at, className, shade, ...rest }) => {
  
  return (
    <div      
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
          },
          className 
        )}
      >
        <img className="mr-2" src={shade === "light" ? logolight : logodark} alt="Logo" height="32"  />
        
      </div>
    </div>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  shade: PropTypes.oneOf(['light', 'dark', 'auth'])
};

Logo.defaultProps = { at: 'auth', shade: 'dark' };

export default Logo;
